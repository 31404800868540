.faq {
  composes: grid from '~g';
  position: relative;
}

.container {
  composes: subgrid from '~g';
  position: relative;
  padding: 0;
}

.content {
  z-index: 1;
  margin-bottom: var(--spacer-32);

  --lockup-column-start: start;
}

.sections {
  z-index: 1;
  grid-column: start/end;

  --lockup-column-span: 4;

  @media (--md) {
    padding-top: var(--spacer-32);

    --lockup-column-span: 8;
  }
  @media (--lg) {
    --lockup-column-span: 6;
  }
}

.sideGlow {
  display: none;
  @media (--lg) {
    position: absolute;
    z-index: var(--z-index-neg);
    top: 15rem;
    bottom: 15rem;
    left: 90vw;
    display: block;
    width: 60rem;
  }
}
