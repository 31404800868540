.caseStudy {
  composes: grid from '~g';
}

.sideRail {
  order: 1;
  padding-top: var(--spacer-24);
  border-top: 1px solid var(--color-alpha-light-20);
  margin-top: var(--spacer-84);
  grid-column: start / end;

  @media (--md) {
    padding-top: var(--spacer-48);
    margin-top: 0;
    grid-column: start / col 4;
    grid-row: 1 / 3;
  }

  @media (--lg) {
    padding-top: var(--spacer-64);
    grid-column: start / col 4;
  }
}

.sideRailLogo {
  margin-bottom: var(--spacer-24);

  @media (--md) {
    margin-bottom: var(--spacer-32);
  }

  @media (--lg) {
    margin-bottom: var(--spacer-28);
  }
}

.sideRailText {
  margin-bottom: var(--spacer-24);

  @media (--md) {
    margin-bottom: var(--spacer-32);
  }

  @media (--lg) {
    margin-bottom: var(--spacer-48);
  }
}

.sideRailStats {
  padding-top: var(--spacer-20);
  border-top: 1px solid var(--color-alpha-light-20);

  @media (--md) {
    margin-bottom: var(--spacer-8);
  }
  @media (--lg) {
    margin-bottom: var(--spacer-16);
  }
}

.introContainer {
  margin-bottom: var(--spacer-84);
  grid-column: start / end;

  @media (--md) {
    border-top: 1px solid var(--color-alpha-light-20);
    margin-bottom: var(--spacer-72);
    grid-column: col 5 / span 8;
  }

  &:has(a) {
    @media (--lg) {
      margin-bottom: var(--spacer-124);
    }
  }
}

.textContainer {
  grid-column: start / end;

  @media (--md) {
    grid-column: col 5 / span 6;
  }

  .link {
    color: var(--color-grey-60);
    pointer-events: auto;
    text-decoration: underline;
  }
}
